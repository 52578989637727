<template>
  <div id="accountClosingForm" class="form">
    <v-form v-if="loaded" ref="form" v-model="valid" lazy-validation>
      <v-text-field v-model="accountClosing.id" label="ID" :required="true" />

      <date-picker
        label="Closed Date"
        :show-hint="false"
        :show-icon="false"
        v-model="accountClosing.closingDate"
      />
      <v-text-field
        v-model="accountClosing.adminUserId"
        label="By User"
        :required="true"
      />

      <date-picker
        label="Created On"
        :show-hint="false"
        :show-icon="false"
        v-model="accountClosing.recordedTime"
      />

      <div class="mt-4 d-flex justify-space-between">
        <v-btn @click="goBack"> Back </v-btn>
        <v-btn @click="handleSubmit" color="primary">
          {{ id ? "Update" : "Save" }}
        </v-btn>
        <span></span>
      </div>
    </v-form>
  </div>
</template>
    
<script>
import { getAccountClosing } from "@/services/accountClosing";
import { mapActions } from "vuex";

export default {
  props: ["id"],
  data() {
    return {
      loaded: false,
      valid: false,
      accountClosing: {
        relations: {},
        id: "",
        closingDate: "",
        adminUserId: "",
        recordedTime: "",
      },
    };
  },
  created() {
    this.setInstance();
  },
  methods: {
    ...mapActions("accountClosing", [
      "createAccountClosing",
      "updateAccountClosing",
    ]),
    ...mapActions("messages", [
      "addMessage",
      "addErrorMessage",
      "addSuccessMessage",
    ]),

    async handleSubmit() {
      if (this.id) {
        let accountClosing = this.accountClosing;
        delete accountClosing.relations;

        this.updateAccountClosing(accountClosing)
          .then((response) => {
            console.log(response);
            this.addSuccessMessage("AccountClosing was updated successfully");
            this.goBack();
          })
          .catch(() => {
            this.addErrorMessage(
              "Error updating AccountClosing, please try again later"
            );
          });
      } else {
        let accountClosing = this.accountClosing;
        delete accountClosing.relations;

        this.createAccountClosing(accountClosing)
          .then((response) => {
            console.log(response);
            this.addSuccessMessage("AccountClosing was created successfully");
            this.goBack();
          })
          .catch(() => {
            this.addErrorMessage(
              "Error creating AccountClosing, please try again later"
            );
          });
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    setInstance() {
      if (this.id) {
        this.accountClosing.id = this.id;
        getAccountClosing(this.id).then((response) => {
          let instance = response.data;
          for (let property in instance) {
            if (
              Object.prototype.hasOwnProperty.call(instance, property) &&
              Object.prototype.hasOwnProperty.call(
                this.accountClosing,
                property
              )
            ) {
              this.accountClosing[property] = instance[property];
            }
          }
          this.loaded = true;
        });
      } else {
        this.loaded = true;
      }
      this.setDependencies();
    },
    setDependencies() {},
  },
  components: {
  },
};
</script>
